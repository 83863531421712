import { SlicePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DougsDatePipe } from '@dougs/ds';
import { SalesInvoice, SalesInvoiceDraft } from '@dougs/sales-invoice/dto';

@Component({
  selector: 'dougs-invoice-date',
  templateUrl: './invoice-date.component.html',
  styleUrls: ['./invoice-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SlicePipe, DougsDatePipe],
})
export class InvoiceDateComponent {
  @Input() invoice!: SalesInvoice | SalesInvoiceDraft;
}
