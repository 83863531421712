import { Pipe, PipeTransform } from '@angular/core';
import { SalesInvoice, SalesInvoiceDraft } from '@dougs/sales-invoice/dto';

@Pipe({
  name: 'showInvoiceDivider',
  standalone: true,
})
export class ShowInvoiceDivider implements PipeTransform {
  transform(invoices: (SalesInvoice | SalesInvoiceDraft)[], index: number, isFirst: boolean): boolean {
    const initialPreviousDate: string | Date | undefined = invoices[index - 1]?.date;
    const initialCurrentDate: string | Date | undefined = invoices[index]?.date;

    if (!initialCurrentDate) {
      return false;
    }
    if (isFirst || !initialPreviousDate) {
      return true;
    }
    const previousDate: Date = new Date(initialPreviousDate);
    const currentDate: Date = new Date(initialCurrentDate);

    return (
      previousDate.getFullYear() !== currentDate.getFullYear() || previousDate.getMonth() !== currentDate.getMonth()
    );
  }
}
