import { NgClass, NgForOf, NgStyle } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'dougs-list-skeleton',
  templateUrl: './list-skeleton.component.html',
  standalone: true,
  imports: [NgForOf, NgClass, NgStyle],
})
export class ListSkeletonComponent {}
