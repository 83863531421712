import { animate, style, transition, trigger } from '@angular/animations';
import { AsyncPipe, CurrencyPipe, DatePipe, NgClass, NgIf, NgPlural, NgPluralCase } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MetricsService } from '@dougs/core/metrics';
import { RoutingService, URL } from '@dougs/core/routing';
import {
  ButtonComponent,
  DropdownComponent,
  DropdownOptionComponent,
  DropdownTriggerDirective,
  IconButtonComponent,
  PillComponent,
} from '@dougs/ds';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import {
  SalesInvoice,
  SalesInvoiceAutofillStatus,
  SalesInvoiceDraft,
  SalesInvoicePaymentStatus,
  SalesInvoiceSource,
  SalesInvoiceType,
} from '@dougs/sales-invoice/dto';
import { InvoiceColorPipe } from '../../pipes/invoice-color.pipe';
import { SalesInvoiceComponentService } from '../../services/sales-invoice/sales-invoice.component.service';
import { OpenSalesInvoiceDrawerService } from '../../services/sales-invoice-drawer/open-sales-invoice-drawer.service';
import { SalesInvoiceItemComponentService } from '../../services/sales-invoice-item.component.service';
import { InvoiceDateComponent } from './invoice-date/invoice-date.component';

@Component({
  selector: 'dougs-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss', '../../styles/sales-invoice-item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    InvoiceDateComponent,
    NgClass,
    NgIf,
    ButtonComponent,
    DropdownTriggerDirective,
    DropdownComponent,
    DropdownOptionComponent,
    CurrencyPipe,
    DatePipe,
    InvoiceColorPipe,
    AsyncPipe,
    PillComponent,
    IconButtonComponent,
    NgPluralCase,
    NgPlural,
  ],
  providers: [SalesInvoiceComponentService, SalesInvoiceItemComponentService],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [style({ opacity: 0 }), animate('300ms ease-in-out', style({ opacity: 1 }))]),
      transition(':leave', [
        style({ opacity: 1, position: 'absolute', top: 0, left: 0 }),
        animate('300ms ease-in-out', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class InvoiceComponent {
  private _invoice!: SalesInvoice | SalesInvoiceDraft;

  canMarkAsPaid = false;
  protected readonly SalesInvoicePaymentStatus = SalesInvoicePaymentStatus;

  @Input() showOperationColumn = false;

  @Input()
  set invoice(invoice: SalesInvoice | SalesInvoiceDraft) {
    this._invoice = invoice;

    this.canMarkAsPaid = this.salesInvoiceComponentService.canMarkAsPaid(invoice);
  }

  get invoice(): SalesInvoice | SalesInvoiceDraft {
    return this._invoice;
  }

  constructor(
    public readonly salesInvoiceComponentService: SalesInvoiceComponentService,
    public readonly salesInvoiceItemComponentService: SalesInvoiceItemComponentService,
    private readonly router: Router,
    private readonly metricsService: MetricsService,
    public readonly companyServicesStateService: CompanyServicesStateService,
    private readonly routingService: RoutingService,
    private readonly openSalesInvoiceDrawerService: OpenSalesInvoiceDrawerService,
  ) {}

  async goToInvoice(): Promise<void> {
    if (this.invoice.isDraft) {
      this.metricsService.pushMixpanelEvent('Invoicing Draft invoice opened');
    }

    const isDraft: boolean = this.invoice.isDraft;
    if (isDraft) {
      await this.router.navigateByUrl(this.routingService.createUrl([URL.SALES_INVOICE, this.invoice.id]).toString());
    } else {
      await this.openSalesInvoiceDrawerService.openSalesInvoiceDrawer(this.invoice as SalesInvoice);
    }
  }

  async duplicateInvoice(): Promise<void> {
    this.metricsService.pushMixpanelEvent('Invoicing Invoice created', {
      Type: 'Duplicated',
      'Location CTA': 'Sandwich menu',
    });

    this.metricsService.pushIntercomEvent('Invoicing Invoice created', {
      Type: 'Duplicated',
      'Location CTA': 'Sandwich menu',
    });
    await this.salesInvoiceItemComponentService.duplicateInvoice(this.invoice);
  }

  protected readonly SalesInvoiceType = SalesInvoiceType;
  protected readonly SalesInvoiceSource = SalesInvoiceSource;
  protected readonly SalesInvoiceAutofillStatus = SalesInvoiceAutofillStatus;
}
