import { Pipe, PipeTransform } from '@angular/core';
import { SalesInvoicePaymentStatus, SalesInvoiceType } from '@dougs/sales-invoice/dto';

@Pipe({
  name: 'invoiceBlankslateLabel',
  standalone: true,
})
export class InvoiceBlankslateLabelPipe implements PipeTransform {
  transform(type: SalesInvoiceType, filter: SalesInvoicePaymentStatus | null): string {
    switch (true) {
      case type === SalesInvoiceType.DRAFT:
        return "Vous n'avez aucun brouillon.";
      case filter === SalesInvoicePaymentStatus.WAITING:
        return "Vous n'avez aucune facture en attente de paiement.";
      case filter === SalesInvoicePaymentStatus.LATE:
        return "Vous n'avez aucune facture en retard de paiement.";
      case filter === SalesInvoicePaymentStatus.PAID:
        return "Vous n'avez aucune facture payée.";
      default:
        return "Vous n'avez aucune facture.";
    }
  }
}
