import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'invoiceColor',
  standalone: true,
})
export class InvoiceColorPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'draft':
        return 'm-orange';
      case 'waiting':
        return 'm-blue';
      case 'late':
        return 'm-red';
      case 'paid':
        return 'm-green';
      default:
        return '';
    }
  }
}
